<template>
  <div class="login">
    <div class="banner"></div>
    <a-form :form="form" class="login-form">
      <a-form-item label="企业代码">
        <a-input
          v-decorator="[
            'companyCode',
            { rules: [{ required: true, message: '请输入企业代码' }] },
          ]"
          allow-clear
          placeholder="请输入企业代码"
        />
      </a-form-item>
      <a-form-item label="手机号">
        <a-input
          v-decorator="[
            'username',
            {
              rules: [{ required: true, message: '请输入手机号' }],
            },
          ]"
          allow-clear
          placeholder="请输入手机号"
        />
      </a-form-item>
      <a-form-item label="密码">
        <a-input-password
          v-decorator="[
            'password',
            {
              rules: [{ required: true, message: '请输入密码' }],
            },
          ]"
          allow-clear
          placeholder="请输入密码"
        />
      </a-form-item>
      <!-- <a-form-item
        ><span class="fz12 ft-grey forget-pwd">忘记密码？</span></a-form-item
      > -->
      <a-form-item>
        <a-button type="primary" class="login-btn" @click="check">
          登录
        </a-button>
      </a-form-item>
      <a-form-item>
        <a-checkbox
          v-decorator="[
            'agreement',
            {
              valuePropName: 'checked',
              rules: [{ required: true, message: '请先阅读协议' }],
            },
          ]"
        >
          <span class="fz12 ft-grey">我已阅读并同意</span>
        </a-checkbox>
        <span class="fz12 ft-grey2" @click="showAgrement">《隐私协议》</span>
        <span class="fz12 ft-grey">接收免除或者限制责任。</span>
      </a-form-item>
    </a-form>
    <a-modal
      :visible="visible"
      title="隐私协议"
      @ok="handleOk"
      @cancel="handleOk"
    >
      <div class="agreement">
        <h4>透明管理云平台隐私政策</h4>
        <h5 class="bold">引言</h5>
        <p>
          透明管理云平台（tm.holdhopecloud.com）及其主办单位重庆吼吼科技有限公司（以下简称：“我们”）重视用户的隐私，隐私权是您重要的权利。您在使用我们的服务时，我们可能会收集和使用您的相关信息。我们希望通过本《隐私政策》向您说明，在使用我们的服务时，我们如何收集、使用、储存和分享这些信息，以及我们为您提供的访问、更新、控制和保护这些信息的方式。本《隐私政策》与您所使用的其明服务息息相关，希望您仔细阅读，在需要时，按照本《隐私政策》的指引，作出您认为适当的选择。本《隐私政策》中涉及的相关技术词汇，我们尽量以简明扼要的表述，并提供进一步说明的链接，以便您的理解。
          您使用或继续使用我们的服务，即意味着同意我们按照本《隐私政策》收集、使用、储存和分享您的相关信息。
        </p>
        <p>
          如对本《隐私政策》或相关事宜有任何问题，请通过邮箱
          houhou@houhoutech.com 或电话 023-65233200 与我们联系。
        </p>
        <h5>我们可能收集的信息</h5>
        <p>
          我们提供服务时，可能会收集、储存和使用下列与您有关的信息。如果您不提供相关信息，可能无法注册成为我们的用户或无法享受我们提供的某些服务，或者无法达到相关服务拟达到的效果。
        </p>
        <h5>您提供的信息</h5>
        <p>
          ·您在注册账户或使用我们的服务时，向我们提供的相关个人信息，例如电话号码、电子邮件、微信号、微信头像等；
        </p>
        <p>
          ·您通过我们的服务向其他方提供的个人信息（包括但须限于电话号码、电子邮件），以及您使用我们的服务时所储存的信息。
        </p>
        <h5>其他方分享的您的信息</h5>
        <p>
          其他方使用我们的服务时所提供有关您的个人信息（包括但须限于电话号码、电子邮件）。
        </p>
        <h5>我们获取的您的信息</h5>
        <p>具体而言，您使用服务时我们可能收集如下信息：</p>
        <p>
          提报信息及个人主页信息，指您使用我们的服务时，系统可能通过cookies或其他方式自动采集的技术信息，包括：设备或软件信息，例如您的移动设备、网页浏览器或用于接入我们服务的其他程序所提供的配置信息、您的IP地址和移动设备所用的版本和设备识别码；
        </p>
        <p>
          在使用我们服务时搜索或浏览的信息，例如您使用的网页搜索词语、访问的社交媒体页url地址，以及您在使用我们服务时浏览或要求提供的其他信息和内容详情；有关您曾使用的移动应用和其他软件的信息，以及您曾经使用该等移动应用和软件的信息；
        </p>
        <h5>位置信息</h5>
        <p>
          指您开启设备定位功能并使用我们基于位置提供的相关服务时，收集的有关您位置的信息，包括：
        </p>
        <p>
          ·您通过具有定位功能的移动设备使用我们的服务时，通过GPS或WiFi等方式收集的您的地理位置信息；
        </p>
        <p>
          ·您或其他用户提供的包含您所处地理位置的实时信息，例如您提供的账户信息中包含的您所在地区信息，您或其他人上传的显示您当前或曾经所处地理位置的共享信息，您或其他人共享的照片包含的地理标记信息；
        </p>
        <p>您可以通过关闭定位功能，停止对您的地理位置信息的收集。</p>
        <h5>使用设备信息与日志信息的功能</h5>
        <p>
          a.为了保障软件与服务的安全运行、运营的质量及效率，我们会收集你的设备的硬件型号、操作系统版本号、设备标识符（Android如IMEI、AndroidID、OAID、IMSI、ICCID、MEID，iOS如IDFV、IDFA；不同的标识符在有效期、是否可由用户重置以及获取方式方面会有所不同）、网络设备硬件地址（MAC地址）、IP
          地址、WLAN接入点（如SSID，BSSID）、蓝牙（Bluetooth）、基站、软件版本号、网络接入方式、类型、状态、网络质量数据、操作、使用、服务日志。以及微信相关信息（含微信名、手机号及头像）。我们可能会将你的设备信息或电话号码与你的帐号相关联。
        </p>
        <p>
          b.为了预防恶意程序、提升运营质量及效率、保障帐号安全，我们会收集安装的应用信息或正在运行的进程信息、应用程序的总体运行、使用情况与频率、应用崩溃情况、总体安装使用情况、性能数据、应用来源。
        </p>
        <p>
          c.为了分析应用新增、激活、留存、性能等统计性指标，我们可能会调用剪切板对相关统计信息进行归因分析，请你放心，在统计过程中，我们不会将你写入剪切板的其他信息回传，不会收集你的隐私信息。
        </p>
        <p>
          d.我们可能使用你的账户信息、设备信息、服务日志信息以及我们关联方、合作方在获得你授权或依法可以提供的信息，用于判断账户及交易安全、进行身份验证、识别违法违规情况、检测及防范安全事件，并依法采取必要的记录、分析、处置措施。
        </p>
        <h5>我们可能如何使用信息</h5>
        <p>我们可能将在向您提供服务的过程之中所收集的信息用作下列用途：</p>
        <p>向您提供服务；</p>
        <p>
          在我们提供服务时，用于身份验证、客户服务、安全防范、诈骗监测、存档和备份用途，确保我们向您提供的产品和服务的安全性；
        </p>
        <p>
          帮助我们设计新服务，改善我们现有服务；使我们更加了解您如何接入和使用我们的服务，从而针对性地回应您的个性化需求，例如语言设定、位置设定、个性化的帮助服务和指示，或对您和其他用户作出其他方面的回应。
        </p>
        <p>
          为了让您有更好的体验、改善我们的服务或您同意的其他用途，在符合相关法律法规的前提下，我们可能将通过某一项服务所收集的信息，以汇集信息或者个性化的方式，用于我们的其他服务。例如，在您使用我们的一项服务时所收集的信息，可能在另一服务中用于向您提供特定内容，或向您展示与您相
          关的、非普遍推送的信息。如果我们在相关服务中提供了相应选项，您也可以授权我们将该服务所提供和储存的信息用于我们的其他服务。
        </p>
        <h5>您如何访问和控制自己的个人信息</h5>
        <p>
          我们将尽一切可能采取适当的技术手段，保证您可以访问、更新和更正自己的注册信息或使用我们的服务时提供的其他个人信息。在访问、更新、更正和删除前述信息时，我们可能会要求您进行身份验证，以保障账户安全。
        </p>
        <h5>我们可能分享的信息</h5>
        <p>
          除以下情形外，未经您同意，受限于届时适用的法律法规，我们以及我们的关联公司不会与任何第三方分享您的个人信息。
        </p>
        <p>
          受限于届时适用的法律法规，我们以及我们的关联公司不会与任何第三方分享您的个人信息。
        </p>
        <p>
          我们以及我们的关联公司，可能将您的个人信息与我们的关联公司、合作伙伴及第三方服务供应商、承包商及代理（例如代表我们发出电子邮件或推送通知的通讯服务提供商、为我们提供位置数据的地图服务供应商）分享（他们可能并非位于您所在的法域），用作下列用途：
        </p>
        <p>·向您提供我们的服务；</p>
        <p>·实现“我们可能如何使用信息”部分所述目的；</p>
        <p>·履行我们在《服务协议》或本《隐私政策》中的义务和行使我们的权利；</p>
        <p>·理解、维护和改善我们的服务。</p>
        <p>
          如我们或我们的关联公司与任何上述第三方分享您的个人信息，我们将努力确保该等第三方在使用您的个人信息时遵守本《隐私政策》及我们要求其遵守的其他适当的保密和安全措施。
        </p>
        <p>
          随着我们业务的持续发展，我们以及我们的关联公司有可能进行合并、收购、资产转让或类似的交易，您的个人信息有可能作为此类交易的一部分而被转移。我们将在转移前通知您。
        </p>
        <p>
          我们或我们的关联公司还可能为以下需要而保留、保存或披露您的个人信息：
        </p>
        <p>·遵守适用的法律法规；</p>
        <p>·遵守法院命令或其他法律程序的规定；</p>
        <p>·遵守相关政府机关的要求。</p>
        <p>
          为遵守适用的法律法规、维护社会公共利益，或保护我们的客户、我们或我们的集团公司、其他用户或雇员的人身和财产安全或合法权益所合理必需的用途。
        </p>
        <h5>信息安全</h5>
        <p>
          我们仅在本《隐私政策》所述目的所必需的期间和法律法规要求的时限内保留您的个人信息。
        </p>
        <p>
          我们使用各种安全技术和程序，以防信息的丢失、不当使用、未经授权阅览或披露。例如，在某些服务中，我们将利用加密技术来保护您提供的个人信息。但请您理解，由于技术的限制以及可能存在的各种恶意手段，在互联网行业，即便竭尽所能加强安全措施，也不可能始终保证信息百分之百的安全。您需要了解，您接入我们的服务所用的系统和通讯网络，有可能因我们可控范围外的因素而出现问题。
        </p>
        <h5>您分享的信息</h5>
        <p>
          我们的多项服务，可让您不仅与自己的社交网络，也与使用该服务的所有用户公开分享您的相关信息，例如，您在我们的服务中所上传或发布的信息（包括您公开的个人信息、您建立的名单）、您对其他人上传或发布的信息作出的回应，以及包括与这些信息有关的位置数据和日志信息。使用我们服务的其他用户也有可能分享与您有关的信息（包括位置数据和日志信息）。特别是，我们的社交媒体服务，是专为使您与世界各地的用户享信息而设计，您可以使共享信息实时、广泛地传递。只要您不删除共享信息，有关信息会一直留存在公共领域即使您删除共享信息，有关信息仍可能由其他用户或不受我们控制的非关联第三方独立地缓存、复制或储存或由其他用户或该等第三方在公共领域保存。
          因此，请您谨慎考虑通过我们的服务上传、发布和交流的信息内容。
        </p>
        <p>
          在一些情况下，您可通过我们某些服务的隐私设定来控制有权浏览您共享信息的用户范围。如要求从我们的服务中删除您的相关信息，请通过该等特别服务条款提供的方式操作。
        </p>
        <p>
          同时，您上次的事项信息在审核通过后，在企业管理员允许的情况下，将同步分享到[案例]模块中，便于更多人查阅参考！
        </p>
        <h5>您分享的敏感个人信息</h5>
        <p>
          某些个人信息因其特殊性可能被认为是敏感个人信息，例如您的种族、宗教、个人健康和医疗信息等。相比其他个人信息，敏感个人信息受到更加严格的保护。
        </p>
        <p>
          请注意，您在使用我们的服务时所提供、上传或发布的内容和信息（例如有关您社交活动的照片等信息），可能会泄露您的敏感个人信息。您需要谨慎地考虑，是否在使用我们的服务时披露相关敏感个人信息。
        </p>
        <h5>我们可能如何收集信息</h5>
        <p>
          我们或我们的第三方合作伙伴，会收集和使用您的信息，并将该等信息储存为日志信息。
        </p>
        <h5>设备权限调用说明</h5>
        <p>
          1）基于摄像头（相机）的服务功能：您可以开启设备摄像头（相机）权限进行拍摄、拍照，以此完成订单/任务中图片及视频的发布，更好的展示或说明设备情况。
        </p>
        <p>
          2）基于图片（图片库/视频库）上传的服务功能：您可以在本系统上传您的照片/图片/视频，来实现事项中图片及视频的发布。
        </p>
        <p>
          3）基于语音（麦克风）技术的服务功能：您可以直接使用麦克风来语音录入或上传声音。
        </p>
        <p>
          4）基于电话（拨打电话、读取通话状态和移动网络信息）的附加服务：为实现与报修方电话沟通，你需要授权本应用使用拨打电话的权限。为进行安全风控，您需要提供您的设备信息，包括通话状态和移动网络信息。为保证应用运行效率及不影响用户正常使用，我们会在应用闲时，即在应用静默或后台运行时进行日志信息和统计数据的上传，该过程需要收集您的设备信息。
        </p>
        <p>
          5）基于存储权限的服务功能：我们会在您启动本应用时申请您设备的存储权限，以便下载、缓存本应用页面内大图片/视频、应用升级文件、发布模板文件和崩溃日志该类必要信息，用于向您提供大图片/视频展示、应用升级、信息发布以及在本地记录崩溃日志信息的功能，最终保证本应用应用终端的稳定运行。为保证应用运行效率及不影响用户正常使用，我们会在应用闲时，即在应用静默或后台运行时进行日志信息和统计数据的上传，该过程需要使用您的存储权限。
        </p>
        <p>
          上述附加功能需要您在您的设备中向我们开启您的摄像头（相机）、图片（图片库/视频库）、语音（麦克风）、电话（拨打电话、读取通话状态和移动网络信息、设备信息）、存储的权限，以实现这些功能所涉及的信息的收集和使用。我们将在您首次下载本应用或者具体使用到上述所列功能时逐一以弹窗的形式提前向您提示，您可以根据您的需要进行选择。您可在您设备的“设置-应用-权限管理”路径，查看、关闭上述系统权限，在不同的设备或系统版本中，相关查看或操作路径和方式可能有所不同，具体请参考设备及系统开发方说明或指引。请您注意，您开启这些权限即代表您授权我们可以收集和使用这些个人信息来实现上述的功能，您关闭权限即代表您取消了这些授权，则我们将不再继续收集和使用您的这些个人信息，也无法为您提供上述与这些授权所对应的功能。您关闭权限的决定不会影响此前基于您的授权所进行的个人信息的处理。
        </p>
        <h5>我们可能向您发送的邮件和信息</h5>
        <h5>邮件和信息推送</h5>
        <p>
          您在使用我们的服务时，我们可能使用您的信息向您的设备发送电子邮件、新闻或推送通知。
        </p>
        <h5>与服务有关的公告</h5>
        <p>
          我们可能在必要时（例如因系统维护而暂停某一项服务时）向您发出与服务有关的公告。您可能无法取消这些与服务有关、性质不属于推广的公告。
        </p>
        <h5>隐私政策的适用范围</h5>
        <p>
          除某些特定服务外，我们所有的服务均适用本《隐私政策》。这些特定服务将适用特定的隐私政策。
          针对某些特定服务的特定隐私政策，将更具体地说明我们在该等服务中如何使用您的信息。
          该特定服务的隐私政策构成本《隐私政策》的一部分。
          如相关特定服务的隐私政策与本《隐私政策》有不一致之处，适用该特定服务的隐私政策。
        </p>
        <p>
          除本《隐私政策》另有规定外，本《隐私条款》所用词语将与《其明服务协议》所定义的词语具有相同的涵义
        </p>
        <h5>请您注意，本《隐私政策》不适用于以下情况：</h5>
        <p>
          ·通过我们的服务而接入的第三方服务（包括任何第三方网站）收集的信息；
        </p>
        <p>·通过在我们服务中进行维修服务的其他公司或机构所收集的信息。</p>
        <h5>变更</h5>
        <p>
          我们可能适时修订本《隐私政策》的条款，该等修订构成本《隐私政策》的一部分。如该等修订造成您在本《隐私政策》下权利的实质减少，我们将在修订生效前通过在主页上显著位置提示或向您发送电子邮件或以其他方式通知您。在该种情况下，若您继续使用我们的服务，即表示同意受经修订的本《隐私政策》的约束。
        </p>
        <h5>第三方sdk目录</h5>
        <p>Umeng（友盟） SDK</p>
        <p>使用目的：统计分析、推送信息、微信登录、分享</p>
        <p>
          收集个人信息类型：设备信息（IMEI/MAC/Android
          ID/IDFA/OpenUDID/GUID/SIM卡IMSI/地理位置等）
        </p>
        <p>
          官网链接：https://developer.umeng.com/docs/119267/detail/118585；https://www.umeng.com/push
        </p>
        <p>隐私权政策链接：https://www.umeng.com/page/policy</p>
        <p>微信开放平台 SDK</p>
        <p>
          使用目的：接入微信开放平台SDK，支持微信授权登录、微信分享和微信支付
        </p>
        <p>数据类型：设备标识信息</p>
        <p>官网链接：https://open.weixin.qq.com/</p>
      </div>
    </a-modal>
  </div>
</template>
<script>
import { login } from "@/utils/api";
// const formItemLayout = {
//   labelCol: { span: 20, offset: 2 },
//   wrapperCol: { span: 20, offset: 2 },
// };
// const formTailLayout = {
//   labelCol: { span: 4 },
//   wrapperCol: { span: 20, offset: 2 },
// };
import { Form, Input, Button, Checkbox, Modal } from "ant-design-vue";
export default {
  components: {
    AForm: Form,
    AFormItem: Form.Item,
    AButton: Button,
    AInputPassword: Input.Password,
    AInput: Input,
    ACheckbox: Checkbox,
    AModal: Modal,
  },
  data() {
    return {
      visible: false,
      checkNick: false,
      loginType: 1,
      //   formItemLayout,
      //   formTailLayout,
      form: this.$form.createForm(this, { name: "dynamic_rule" }),
    };
  },
  methods: {
    check() {
      this.form.validateFields((err, values) => {
        if (!err) {
          let params = {
            companyCode: values.companyCode,
            password: values.password,
            username: values.username,
          };
          this.login(params);
        }
      });
    },
    showAgrement() {
      this.visible = true;
    },
    handleOk() {
      this.visible = false;
    },
    async login(params) {
      const { resp_code, resp_msg, data } = await login(params);
      if (resp_code == 1) {
        this.$message.error(resp_msg);
        localStorage.removeItem("accessToken");
        localStorage.removeItem("loginParams");
      } else {
        let accessToken = data.accessToken;
        let origintpath = localStorage.getItem("origintpath");
        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("loginParams", JSON.stringify(params));
        if (origintpath) {
          window.location.href = origintpath;
        } else {
          this.$router.push("/home");
        }
        // this.$router.push("/home");
        // this.$router.back();
      }
    },
    // handleChange(e) {
    //   this.checkNick = e.target.checked;
    // },
  },
};
</script>
<style lang="less">
.login {
  background: #fff;
  height: 100%;
  padding: 0 3rem;
  padding-bottom: 2rem;
  .banner {
    width: 100%;
    max-width: 75rem;
    height: 34.4rem;
    background: url("../assets/zzxdn_img.png") no-repeat center;
    background-size: 30rem;
  }
  .login-form {
    .ant-form-item-label {
      text-align: left;
      line-height: 14px;
    }
    .ant-form-item-required::before {
      content: "";
    }
    .ant-form-item-label > label::after {
      content: "";
    }
    input.ant-input {
      border: 0;
      border-bottom: 1px solid #dcdcdc;
      padding-left: 5px;
    }
    .ant-form-item {
      margin-bottom: 20px !important;
    }
    .ant-form-explain {
      padding-left: 5px;
    }
    .ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner,
    .ant-checkbox-input:focus + .ant-checkbox-inner {
      border-color: #f39800;
    }
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #f39800;
    }
    .ant-checkbox-checked::after {
      border: 1px solid #f39800;
    }
  }
  .forget-pwd {
    display: block;
    text-align: right;
    position: relative;
    margin-top: -20px;
  }
  .login-btn {
    width: 100%;
    height: 4.4rem;
    background: linear-gradient(270deg, #f39800 0%, #ffac22 100%);
    border-radius: 4.4rem;
    font-size: 1.7rem;
  }
  .login-close {
    position: absolute;
    right: 0;
    color: #bdbdbd;
    top: 1rem;
  }
}
.agreement {
  height: 60vh;
  overflow-y: scroll;
  h4 {
    text-align: center;
    font-weight: bold;
    font-size: 1.8rem;
  }
  h5 {
    font-weight: bold;
    font-size: 1.4rem;
    margin-top: 1rem;
  }
}
</style>